import React from 'react';
import './Cards.css';
import PopUpSection from './PopUpSection';

function Cards() {
  return (
    <PopUpSection />
  );
}

export default Cards;
