import React, { useState, useEffect } from 'react';
// import './PopUpSection.css';
import ScrollComponent from './ScrollComponent';

const PopUpSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='cards'>
      <div className="container">
      {isMobile ? (
          // Mobile layout
          <>
        <div className="left-side">
          <ScrollComponent>
            <img className="pic1" src="images/aboutus.png" alt="About Us" />
          </ScrollComponent>
          <p className='space'></p>
          <ScrollComponent>
            <h1 className='other2'>About Us</h1>
            <p className='about2'>
              NIW Action is a dedicated agency specializing in EB1, EB2-NIW, and O1 visa applications, famous for handling non-academic cases. We're based in San Jose, California, and our team was founded by three professionals from leading technology/consulting firms. We understand the struggles and challenges that international students and H1B visa holders face in the U.S. immigration system, and we're here to provide a supportive and efficient service. We've helped data scientists and engineers from big names like TikTok, Meta, Google, Amazon, and PwC successfully navigate the NIW process.
            </p>
          </ScrollComponent>
        </div>
        <div className="right-side">
          <p className='space'></p>
          <p className='space'></p>
          <p className='space'></p>
          <ScrollComponent>
            <img className="pic2" src="images/hand.png" alt="Hand Shake" />
          </ScrollComponent>
          <p className='space'></p>
          <ScrollComponent>
            <h1 className='other1'>Our Commitment</h1>
            <p className='about1'>
              At NIW Action, we value understanding and empathy above all. We take a practical and down-to-earth approach to meet each client's needs, offering sincere and professional support to help ease any anxiety or uncertainty you may feel. Our aim is to provide a clear, transparent, and efficient process that leaves you feeling comfortable and respected. We know the immigration system can be tough, and we're here to make it a little easier for you. Unlike other firms, we ask only the essential questions, saving you time and energy, and lightening your load. We will take care of the rest.
            </p>
            <p className='about1'>
              We understand the challenges and pressures you face while studying and working in the U.S. That's why we're committed to providing a service experience that's different. We're not just your service providers; we're your partners, standing by your side as you navigate the complexities of immigration. Our goal is to ensure you feel supported and cared for throughout the entire process.
            </p>
          </ScrollComponent>
          <p className='space'></p>
        </div>
        </>
      ) : (
        // Desktop layout
        <>
        <div className="left-side">
        <ScrollComponent>
          <img className="pic1" src="images/aboutus.png" alt="About Us" />
        </ScrollComponent>
        <p className='space'></p>
        <ScrollComponent>
          <h1 className='other1'>Our Commitment</h1>
          <p className='about1'>
          At NIW Action, we value understanding and empathy above all. We take a practical and down-to-earth approach to meet each client's needs, offering sincere and professional support to help ease any anxiety or uncertainty you may feel. Our aim is to provide a clear, transparent, and efficient process that leaves you feeling comfortable and respected. We know the immigration system can be tough, and we're here to make it a little easier for you. Unlike other firms, we ask only the essential questions, saving you time and energy, and lightening your load. We will take care of the rest.
          </p>
          <p className='about1'>
          We understand the challenges and pressures you face while studying and working in the U.S. That's why we're committed to providing a service experience that's different. We're not just your service providers; we're your partners, standing by your side as you navigate the complexities of immigration. Our goal is to ensure you feel supported and cared for throughout the entire process.
          </p>
        </ScrollComponent>
      </div>
      <div className="right-side">
        <ScrollComponent>
          <h1 className='other2'>About Us</h1>
          <p className='about2'>
          NIW Action is a dedicated agency specializing in EB1, EB2-NIW, and O1 visa applications, famous for handling non-academic cases. We're based in San Jose, California, and our team was founded by three professionals from leading technology/consulting firms. We understand the struggles and challenges that international students and H1B visa holders face in the U.S. immigration system, and we're here to provide a supportive and efficient service. We've helped data scientists and engineers from big names like TikTok, Meta, Google, Amazon, and PwC successfully navigate the NIW process.
          </p>
        </ScrollComponent>
        <p className='space'></p>
        <ScrollComponent>
          <img className="pic2" src="images/hand.png" alt="Hand Shake" />
        </ScrollComponent>
      </div>
        </>
      )}
      </div>
    </div>
  );
};

export default PopUpSection;