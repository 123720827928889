// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-component {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}

.scroll-component.visible {
  opacity: 1;
  transform: translateY(0);
}`, "",{"version":3,"sources":["webpack://./src/components/ScrollComponent.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,2BAA2B;EAC3B,0DAA0D;AAC5D;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B","sourcesContent":[".scroll-component {\n  opacity: 0;\n  transform: translateY(20px);\n  transition: opacity 1.5s ease-out, transform 1.5s ease-out;\n}\n\n.scroll-component.visible {\n  opacity: 1;\n  transform: translateY(0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
