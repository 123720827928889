import React, { useEffect } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  useEffect(() => {
    const video = document.querySelector('.video-background');
    if (video) {
      video.setAttribute('playsinline', 'true');
      video.setAttribute('muted', 'true');

      // Fallback to play on interaction
      const playVideo = () => {
        if (video.paused) {
          video.play();
        }
      };

      document.addEventListener('touchstart', playVideo);
      return () => {
        document.removeEventListener('touchstart', playVideo);
      };
    }
  }, []);

  return (
    <div className='hero-container'>
      <video src='/videos/video-1.mp4' className ="video-background inlinevideo" autoPlay loop muted />
      <h1>Start Your Green Card Journey with Us</h1>
      <p>Faster, Smarter, and Stronger Path to Your Success</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          link='https://susy90t4u3q9.larksuite.com/share/base/form/shrusGymsWjZHh9n33d6ogPDRug'
        >
          START FREE EVALUATION
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
