import React from 'react';
import '../../App.css';
import NewService from '../NewService';
import Footer from '../Footer';

function Home() {
  return (
    <>
      <NewService />
      <Footer />
    </>
  );
}

export default Home;
