import React, { useState, useEffect, useRef } from 'react';
import './ScrollComponent.css'; // Import the CSS file for styling

const ScrollComponent = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const top = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (top < windowHeight) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`scroll-component ${isVisible ? 'visible' : ''}`}
    >
      {children}
    </div>
  );
};

export default ScrollComponent;
