// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;EAC7B,WAAW;EACX,iBAAiB;EACjB,gCAAgC;EAChC,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;EAEE,6BAA6B;EAC7B,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[":root {\n  --primary: #fff;\n}\n\n.btn {\n  padding: 8px 20px;\n  border-radius: 2px;\n  outline: none;\n  border: none;\n  cursor: pointer;\n}\n\n.btn--primary {\n  background-color: var(--primary);\n  color: #242424;\n  border: 1px solid var(--primary);\n}\n\n.btn--outline {\n  background-color: transparent;\n  color: #fff;\n  padding: 8px 20px;\n  border: 1px solid var(--primary);\n  transition: all 0.3s ease-out;\n}\n\n.btn--medium {\n  padding: 8px 20px;\n  font-size: 18px;\n}\n\n.btn--large {\n  padding: 12px 26px;\n  font-size: 20px;\n}\n\n.btn--large:hover,\n.btn--medium:hover {\n  transition: all 0.3s ease-out;\n  background: #fff;\n  color: #242424;\n  transition: 250ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
