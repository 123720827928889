import React, { useState } from 'react';
import './qa.css';

function Qa() {
    // return(
    //     <div className='qas'>
    //         <h1 className='header'>Q&A</h1>
    //         <p className='question'>Q1: 我没有论文也没有专利，可以申请 NIW 吗？</p>
    //         <p className='answer'>A1: 可以！专利和论文都不是申请 NIW 的必要条件。我们有丰富的非学术类案例成功经验，擅长帮助你找到项目与国家利益 (National Importance) 的共通点，并论述你的计划和贡献 (Proposed Endeavor)。</p>
    //         <p className='question'>Q2: 为什么选择你们？你们相较于其他律所有什么优势？</p>
    //         <p className='answer'>A2: 我们的优势在于：</p>
    //         <p className='answer'>1. 技术背景与实际经验：我们的团队不仅有科技背景，而且都是 NIW 申请的亲历者，深知申请流程中的每一个细节和挑战。</p>
    //         <p className='answer'>2. 材料要求简化：我们精简材料要求，最大限度地减少客户负担，确保在阐述和论证 National Importance 时更加精准有效。</p>
    //         <p className='answer'>3. 分阶段收费：我们采用分阶段收费模式，避免一开始就收取高额费用。如果你不满意，可以随时退出，保障灵活度。</p>
    //         <p className='answer'>4. 高效提交与明确截止日期：我们承诺在 3-10 周内递交申请，并将截止日期写入合同，杜绝拖延现象，确保你安心等待结果。</p>
    //         <p className='answer'>我们深刻理解客户在移民申请中的焦虑和不安，因此，我们致力于提供透明、高效和贴心的服务，让你在整个过程中感到被支持和理解。</p>
    //         <p className='question'>Q3: 我不是科技领域的申请人，也可以找你们吗？</p>
    //         <p className='answer'>A3: 完全可以。尽管我们的核心成员主要来自科技领域，但我们在处理多个行业的申请方面有着丰富的经验。除了科技行业，我们还成功帮助过艺术、金融、教育等领域的客户。我们注重每一个客户的独特背景，深入挖掘其专业领域的亮点，确保每一份申请都精准、高效。我们都致力于提供清晰透明的服务，让你在申请过程中感到放心和支持。</p>
    //         <p className='question'>Q4: 公司正在给我办 PERM，我也可以同时申请 NIW 吗？</p>
    //         <p className='answer'>A4: 完全可以。NIW 相较于 PERM 的优势在于：无需劳工证，减少了繁琐的程序；可以自行申请，不依赖雇主，更加灵活。此外，NIW 强调个人成就和国家利益，审批侧重点不同，成功几率更高。同时进行 PERM 和 NIW 申请，两者互不冲突，为你的移民之路提供更多保障。</p>
    //         <p className='question'>Q5: 我的 NIW 申请被 RFE 了，可以找你们帮忙回复吗？</p>
    //         <p className='answer'>A5: 可以。我们提供合理收费的 RFE 咨询服务，也可以为你处理 RFE 的文书工作。处理 RFE 的文书过程往往比初次申请更复杂，需要深入分析和精准应对，费用相对较高，希望大家理解。举例来说，就像修改他人的代码比自己写代码更为费力。</p>
    //         <p className='question'>Q6: 独立推荐人是否必须？</p>
    //         <p className='answer'>A6: 不是必须。虽然有独立推荐人更好，但这并不是决定性因素。内容大于形式，重要的是材料能证明 NIW 的三大标准。只要你的证据矩阵足够有力，就能成功。请对自己的材料有信心。</p>
    //         <p className='question'>Q7: 我可以看到完整的 Petition Letter吗？</p>
    //         <p className='answer'>A7: 我们非常重视客户的知情权。我们会与您详细沟通 Peition Letter 的全文写作思路，并提供 Proposed Endeavors的相关内容，确保服务过程中您可以充分了解我们的工作质量。但由于知识产权和律师在法律方面需要独立执业等合规性的限制，我们无法通过书面的方式提供与法律、格式文本等相关的内容。</p>
    //         <h1 className='header2'>NIW 申请常见问题解答（仅供参考，不构成法律意见）</h1>
    //         <p className='question'>Q1: NIW 被拒之后，通常是否会有不好的绿卡移民记录？</p>
    //         <p className='answer'>A1: 每个申请都是独立审核的，不影响。但表格上会问有没有提交过移民申请，如实回答即可。</p>
    //         <p className='question'>Q2: 如果再次提交 NIW，新的申请是否会受之前的记录影响？</p>
    //         <p className='answer'>A2: 和问题 1 同理，每个申请都是独立审核的，不影响。但表格上会问有没有提交过移民申请，如实回答即可。</p>
    //         <p className='question'>Q3: 如果再次提交别的绿卡申请（非 NIW），新的申请是否会受之前的记录影响？</p>
    //         <p className='answer'>A3: 和问题 1 同理，每个申请都是独立审核的，不影响。但表格上会问有没有提交过移民申请，如实回答即可。</p>
    //         <p className='question'>Q4: 拒绝的记录是否会对非移民签证状态造成影响？</p>
    //         <p className='answer'>A4: 对 H1B、L1 这类允许有双重意图的申请不影响。对 F-1、B-1/2 或者没有签证的海外人士还是有影响的。但具体影响要看具体情况：提交过移民申请本身并不会直接导致其他签证类型就一定不能使用。</p>
    //         <div className='qas'></div>
    //     </div>
    // );
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const qas = [
        {
            question: "I don't have any papers or patents. Can I still apply for NIW?",
            answer: "Yes! Neither papers nor patents are required to apply for NIW. We have extensive experience with non-academic cases and are skilled in identifying how your projects align with national interest. We can help you articulate your plans and contributions (Proposed Endeavor)."
        },
        {
            question: "Why should I choose your services over other law firms? What are your advantages?",
            answer: `Our advantages include:
                    1. Technical Background and Practical Experience: Our team not only has a strong technical background but also includes individuals who have personally gone through the NIW application process. We understand every detail and challenge of the application process.
                    2. Simplified Material Requirements: We streamline the material requirements, ensuring a more precise and effective presentation of National Importance while making the process as straightforward as possible for our clients. While other firms may require applicants to prove the three NIW criteria themselves, we specialize in extracting and emphasizing the alignment with U.S. national interests from the applicant's concise yet crucial information.
                    3. Phased Payment Structure: We use a phased payment model, avoiding high upfront costs. If you are not satisfied, you can exit the process at any time, offering great flexibility.
                    4. Efficient Submission and Clear Deadlines: We commit to submitting your application within 3-10 weeks and include specific deadlines in the contract. This eliminates delays and ensures peace of mind as you wait for the results.`
        },
        {
            question: "I am not working in the tech industry. Can I still choose your service?",
            answer: "Absolutely. Although our core team members primarily come from the tech industry, we have extensive experience handling applications from various sectors. In addition to the tech industry, we've successfully assisted clients in fields such as arts, finance, and education. We focus on each client's unique background, digging deep into the highlights of their professional field to ensure that every application is precise and effective. We are committed to providing clear and transparent services, so you can feel confident and supported throughout the application process."
        },
        {
            question: "My company is currently processing my PERM. Can I apply for NIW at the same time?",
            answer: "Absolutely. The NIW has several advantages over PERM, such as not requiring a labor certification, which simplifies the process, and allowing you to apply independently, without relying on an employer, offering more flexibility. Moreover, NIW focuses on individual achievements and national interest, with different approval criteria, potentially increasing your chances of success. Applying for both PERM and NIW simultaneously does not create any conflict, providing you with more security on your immigration path."
        },
        {
            question: "My NIW application received an RFE. Can I ask for your help in responding?",
            answer: "Yes. We offer reasonably priced RFE consultation services and can also handle the documentation work for your RFE response. The process of addressing an RFE is often more complex than the initial application, requiring in-depth analysis and precise responses, which can make the cost relatively higher.  To illustrate, it’s similar to the effort involved in modifying someone else's code, which is often more challenging than writing your own."
        },
        {
            question: "Are independent recommenders mandatory for the NIW application process?",
            answer: "No. While having independent recommenders is beneficial, the strength of your case doesn’t solely depend on them. Content is more important than form; what matters is that your materials can demonstrate the three key criteria for NIW. As long as your evidence matrix is strong enough, you can succeed. Please have confidence in your materials."
        },
        {
            question: "Why don’t you provide a complete petition letter to your clients?",
            answer: "We highly value our clients' right to be informed. We will thoroughly discuss the full writing strategy of the petition letter with you and provide the content related to your proposed endeavors, ensuring that you can fully understand the quality of our work throughout the process. However, due to intellectual property considerations and compliance requirements, such as the need for attorneys to practice independently in legal matters, we are unable to provide written materials related to legal content and formatted text."
        },
        // Add more Q&A pairs as needed
    ];

    const qan = [
        {
            question: "Will a NIW denial result in a negative immigration record?  ",
            answer: "Each application is reviewed independently, so it will not negatively impact your record. However, immigration forms will ask if you have previously submitted an immigration application, and you should answer truthfully."
        },
        {
            question: "Will a new NIW application be affected by the previous denial? ",
            answer: "Similar to the first question, each application is reviewed independently and will not be affected by a prior denial. You just need to answer truthfully on the form if asked about previous immigration applications."
        },
        {
            question: "Will a new green card application (non-NIW) be affected by the previous NIW denial?",
            answer: "Again, each application is reviewed independently, so a prior NIW denial should not affect a new green card application. Just be sure to answer honestly about previous immigration applications when asked."
        },
        {
            question: "Will a denial record affect my non-immigrant visa status?",
            answer: "For dual-intent visas like H1B or L1, it will not have an impact. However, it might affect F-1, B-1/2, or those without a visa. The specific impact will depend on the individual case; having a prior immigration application doesn't automatically disqualify you from obtaining other visa types."
        },
    ];

    return (
        <div className='qas'>
            <h1 className='header'>Frequently Asked Questions</h1>
            {qas.map((qa, index) => (
                <div key={index} className='qa-frame'>
                    <div className={`question-container ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => toggleAnswer(index)}
                    >
                        <p className={`question ${activeIndex === index ? 'active-question' : ''}`}>{qa.question}</p>
                        <span className={`arrow ${activeIndex === index ? 'arrow-up' : 'arrow-down'}`}></span>
                    </div>
                    {activeIndex === index && (
                        <div className='answer-container'>
                            {qa.answer.split('\n').map((line, i) => (
                                <p key={i} className='answer'>{line}</p>
                            ))}
                        </div>
                    )}
                </div>
            ))}
            <h1 className='header'>Common Questions About NIW (Doesn't Constitute Legal Advice)</h1>
            {qan.map((qa, index) => (
                <div key={index} className='qa-frame'>
                    <div className={`question-container ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => toggleAnswer(index)}
                    >
                        <p className={`question ${activeIndex === index ? 'active-question' : ''}`}>{qa.question}</p>
                        <span className={`arrow ${activeIndex === index ? 'arrow-up' : 'arrow-down'}`}></span>
                    </div>
                    {activeIndex === index && (
                        <div className='answer-container'>
                            {qa.answer.split('\n').map((line, i) => (
                                <p key={i} className='answer'>{line}</p>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Qa;