import React, { useState, useEffect } from 'react';
import './service.css';


export default function Services() {
const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

const [selectedSection, setSelectedSection] = useState('services');

  const sections = [
    { name: 'Service Content', id: 'services' },

    { name: 'Service Process', id: 'process' },

    { name: 'Why Choose Us?', id: 'faq' },
  ];

  const renderContent = () => {

    switch (selectedSection) {
      case 'services':
        return (
          <>
            <h2 className="section">Service Content</h2>
            <p className="content">Our services are divided into two main areas: Tech Support (storytelling of professional experience and achievements) and Legal Support.</p>
            <p className='space'></p>
            <div className="grid-container-s">
                <div className="left-column-s">
                  <div className="grid-item-c">Tech Support</div>
                  <div className="grid-item-s">With our team's strong technical expertise, we have a deep understanding of your work and accomplishments. This allows us to craft well-structured and logically sound Petition Letters that clearly demonstrate how you meet the NIW requirements. We delve into your daily work and career achievements, aligning them with the U.S. policies and national strategies to showcase the significant contributions you can make to the country, thus enhancing the likelihood of NIW approval.</div>
                  <div className="grid-item-s"><p>The USCIS criteria for determining National Interest Waiver (NIW) eligibility include:</p>
                <p className='space'></p>
                <p>1. The person's proposed endeavor has both substantial merit and national importance;</p>
                <p>2. The person is well positioned to advance the proposed endeavor; </p>
                <p>3. It would be beneficial to the United States to waive the job offer and thus the permanent labor certification requirements</p></div>
                </div>
                <div className="right-column-s">
                  <div className="grid-item-c">Legal Support</div>
                  <div className="grid-item-s">We collaborate with highly-rated law firms in Silicon Valley to ensure that all processes comply with legal standards. Our legal team provides comprehensive support for your application, ensuring that every step is clear and transparent.</div>
                  <a href="https://yclawgroup.com" target="_blank" rel="noopener noreferrer">
                    <img src="images/YC.png" alt="law group" className="process-image" />
                  </a>
                </div>
              </div>
          </>
        );
      case 'process':
        return (
          <>
            <h2 className="section">Service Process Overview</h2>
            <p className="content-s">Academic and Industry Plans</p>
            <p className="content2">We offer different pricing plans based on the duration and urgency of the case (3-10 weeks), suitable for both academic and industry professionals.</p>
            <p className="content-s">Phased Payment Structure</p>
            <p className="content2">We implement a phased payment structure to ensure transparency and control at every stage of the process.</p>
            <p className="content2"><strong>Deposit:</strong> $500 (Collected upon signing the contract)</p>
            <p className="content2"><strong>Recommendation Letters Fee:</strong> Charged after the completion of the first recommendation letter</p>
            <p className="content2"><strong>Filing Fee:</strong> Charged when the documents are filed, and the I-140 application fee and optional premium processing fee</p>
            <p className="content2"><strong>Approval Fee:</strong> $1000 (Charged upon approval)</p>
            <p className="content-s">Customized Client Support</p>
            <p className="content2">We make sure each client receives enough support from us. All inquiries are promptly addressed and documented, ensuring team alignment and efficient communication.</p>
            <p className='content-d'>Phased Payment Process Diagram</p>
            <img src="images/timeline.png" alt="timeline" className="timeline" />
          </>
        );
      case 'faq':
        return (
          <>
            <h3 className="section">Common Challenges and Our Solutions</h3>
            <div className="s-content">
              <p className='content'>We recognize the common difficulties clients face during the application process and offer practical solutions:</p>
              <p className='space'></p>
              <div className="grid-container">
                <div className="left-column">
                  <div className="grid-item">❌ Lawyers may not fully understand the complexities of your field and often require you to prove the three NIW criteria on your own, which can be challenging and time-consuming.</div>
                  <div className="grid-item">❌ Lawyers often provide a tentative submission timeline without clearly committing to a specific deadline.</div>
                  <div className="grid-item">❌ Communication with lawyers can be slow, frustrating, and often lack the much-needed transparency and clarity.</div>
                </div>
                <div className="right-column">
                  <div className="grid-item">✅ Our tech support team has diverse expertise across technology, finance, and the arts, allowing us to effectively highlight how your experience aligns with U.S. national interests.</div>
                  <div className="grid-item">✅ We commit to specific deadlines and include them in our contract, ensuring a reliable and predictable process.</div>
                  <div className="grid-item">✅ We provide dedicated customer service, offering regular updates to keep you fully informed about the progress of your case.</div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className='all'>
      <header className="page-header">
        <h1>Services</h1>
        <p className='hp'>Providing Everything You Need</p>
      </header>
    <div className="service-container">
      <div className="sidebar">
        {sections.map((section, index) => (
          <button
            key={index}
            className={`sidebar-item ${selectedSection === section.id ? 'active' : ''}`}
            onClick={() => setSelectedSection(section.id)}
          >
            {section.name}
          </button>
        ))}
      </div>
      <div className="content-area">
        {renderContent()}
      </div>
    </div>
    </div>
  );
}