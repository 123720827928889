import React from 'react';
import './Footer.css';
// import { Button } from './Button';
import RedLittleBookIcon from '../components/xhs.svg';
// import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Contact Us
        </p>
        <p className='footer-subscription-text'>
        Start a conversation with us through the following media channels
        </p>
      </section>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='social-icons'>
            <a
              className='social-icon-link linkedin'
              href='https://www.linkedin.com/in/niw-action-638345319/'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </a>
            <a
              className='social-icon-link email'
              href='mailto:niwaction@gmail.com'
              aria-label='Email'
            >
              <i className='fas fa-envelope' />
            </a>
            <a
              className='social-icon-link red-little-book'
              href='https://www.xiaohongshu.com/user/profile/58ad85ea11b4e721476d0fc3'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Red Little Book'
            >
              <img src={RedLittleBookIcon} alt='Red Little Book' className='custom-icon'/>
            </a>

          </div>
        </div>
      </section>
      <small class='website-rights'>© NIW Action 2024</small>
    </div>
  );
}

export default Footer;
